import React, { useState } from 'react';

function FileInput() {
    const [fileData, setFileData] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            const base64String = reader.result;
            setFileData(base64String);
        };

        reader.readAsDataURL(file);
    };

    return (
        <div style={{ border: '3px solid blue', borderRadius: '20px', margin: '10px', padding: '10px', display: 'flex', flexDirection: 'row' }}>
            <div style={{ border: '1px solid red', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                <input type="file" onChange={handleFileChange} />
            </div>
            {fileData && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <h2>Base64 String:</h2>
                    <textarea value={fileData} readOnly rows={10} cols={50} />
                    <img src={fileData} style={{ width: 300 }} />
                    {/* <img src={`data:image/png;base64,${base64}`} /> */}
                </div>
            )}
        </div>
    );
}

export default FileInput;
