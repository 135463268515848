import React, { useEffect, useState } from 'react'
import axios from 'axios'
import "./Posts.css"

const Posts = () => {
    const [state, setState] = useState([])
    useEffect(() => {
        const url = 'http://localhost/wp-headless/server/wp-json/wp/v2/posts'
        axios.get(url)
            .then((response) => setState(response?.data))
            .catch((error) => console.log("error", error))
    }, [])

    const userDetails = JSON.parse(localStorage.getItem('UID'))
    const handleSelectFile = (event) => {
        const file = event.target.files[0]

        const formData = new FormData();
        formData.append('file', file);
        formData.append('title', file.name);
        formData.append('type', file.type);

        setTimeout(() => {
            const url = 'http://localhost/wp-headless/server/wp-json/wp/v2/media'
            axios.post(url, formData, {
                // headers: {
                //     'Content-Type': 'multipart/form-data'
                // }
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer' + userDetails.token
                }

            })
        }, 2000);
    }

    return (
        <div className='container'>
            {state?.map((data) => {
                return (
                    <div className='card'>
                        <img src={data.featured_src ? data.featured_src[0] : "http://localhost/wp-headless/server/wp-content/uploads/2024/02/upskill1.jpg"} />
                        <h2 className='custom-text'>{data?.title?.rendered}</h2>
                        <p dangerouslySetInnerHTML={{ __html: data?.excerpt?.rendered }}></p>
                    </div>
                )
            })}
            {/* <div>
                <input type='file' onChange={(e) => handleSelectFile(e)} />
            </div> */}
        </div>
    )
}


export default Posts;