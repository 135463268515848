import React, { useEffect, useRef, useState } from "react";
import './contentSlice.css'

const delay = 2500;

const Slideshow = ({ postsData }) => {
    const [index, setIndex] = useState(0);
    const timeoutRef = useRef(null);

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setIndex((prevIndex) =>
                    prevIndex === postsData.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );

        return () => {
            resetTimeout();
        };
    }, [index]);


    return (
        <div className="slideshow">
            <div
                className="slideshowSlider"
                style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
            >
                {postsData?.map((data, index) => (
                    <div
                        className="slide"
                        key={index}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row', width: '90%', height: '100%' }}>
                            <div style={{ height: '100%', width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <p style={{ width: '80%', padding: "0px 70px 0px 70px", fontSize: '40px', fontWeight: 'bold', color: '#3c4592', textAlign: 'center', textWrap: 'wrap' }}>
                                    {data.title}
                                </p>
                                <p style={{ width: '80%', padding: "0px 70px 0px 70px", textAlign: 'center', textWrap: 'wrap' }}>
                                    {data.content}
                                </p>
                            </div>
                            <div style={{ height: '100%', width: '20%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={data.image.url} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="slideshowDots">
                {postsData.map((_, idx) => (
                    <div
                        key={idx}
                        className={`slideshowDot${index === idx ? " active" : ""}`}
                        onClick={() => {
                            setIndex(idx);
                        }}
                    ></div>
                ))}
            </div>
        </div>
    );
}


export default Slideshow