import React, { useEffect, useState } from 'react';
import axios from 'axios';

const UploadCustomImage = () => {
    const [file, setFile] = useState('');
    const [uploadResponse, setUploadResponse] = useState('');
    const [imagePath, setImagePath] = useState('');

    const [Trainingfile, setTrainingFile] = useState('');
    const [traininguploadResponse, settrainingUploadResponse] = useState('');
    const [TrainingImagePath, setTrainingImagePath] = useState('');
    const userDetails = JSON.parse(localStorage.getItem('UID'))

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleTrainigFileChange = (event) => {
        setTrainingFile(event.target.files[0]);
    };

    const handleGetImages = async () => {
        try {
            const response = await axios.get('http://localhost/wp-headless/server/wp-json/custom/v1/get-custom-images');
            setImagePath(response.data.image_url);
        } catch (error) {
            console.error('Error retrieving images:', error);
        }
    };

    const handleUpload = () => {
        const formData = new FormData();
        formData.append('image', file);
        const response = axios.post('http://localhost/wp-headless/server/wp-json/custom/v1/upload-custom-image', formData, {
            headers: {
                'Authorization': 'Bearer ' + userDetails.token,
                'Content-Type': 'multipart/form-data'
            }
        });
        setUploadResponse(response.data);
        handleGetImages();
    };

    const handleGettrainingImages = async () => {
        try {
            const response = await axios.get('http://localhost/wp-headless/server/wp-json/custom/v1/get-training-images');
            setTrainingImagePath(response.data.image_url);
        } catch (error) {
            console.error('Error retrieving images:', error);
        }
    };

    const handletrainingUpload = () => {
        const formData = new FormData();
        formData.append('image', Trainingfile);
        const response = axios.post('http://localhost/wp-headless/server/wp-json/custom/v1/upload-training-image', formData, {
            headers: {
                'Authorization': 'Bearer ' + userDetails.token,
                'Content-Type': 'multipart/form-data'
            }
        });
        settrainingUploadResponse(response.data);
        handleGettrainingImages();
    };

    useEffect(() => {
        handleGetImages();
        handleGettrainingImages();
    }, [])


    return (
        <div style={{ display: "flex", flexDirection: 'row', width: '100%', padding: '10px', justifyContent: 'space-evenly' }}>
            <div style={{ border: '2px solid green', width: "45%", borderRadius: '10px', padding: '10px', marginBottom: 10, height: '250px' }}>
                <input type="file" onChange={handleFileChange} />
                <button onClick={handleUpload}>Upload Image</button>
                {uploadResponse && <p>{uploadResponse}</p>}
                {imagePath && <img src={imagePath} style={{ width: '200px' }} alt="Uploaded Image" />}
            </div>
            <div style={{ border: '2px solid green', width: "45%", borderRadius: '10px', padding: '10px', height: '250px' }}>
                <input type="file" onChange={handleTrainigFileChange} />
                <button onClick={handletrainingUpload}>Upload Image</button>
                {traininguploadResponse && <p>{traininguploadResponse}</p>}
                {TrainingImagePath && <img src={TrainingImagePath} style={{ width: '200px' }} alt="Uploaded Image" />}
            </div>
        </div>
    );
};

export default UploadCustomImage;