import React, { useEffect, useState } from 'react';
import axios from 'axios';

const SlideDataForm = () => {
    const [id, setId] = useState('');
    const [title, settitle] = useState('');
    const [content, setcontent] = useState('');
    const [image, setImage] = useState(null);
    const [getallSlideData, setgetallSlideData] = useState([]);
    const userDetails = JSON.parse(localStorage.getItem('UID'));

    const handleFileChange = (e) => {
        setImage(e.target.files[0]);
    };
    console.log('getallSlideData', getallSlideData);
    const getAllData = () => {
        axios.get("http://localhost/wp-headless/server/wp-json/custom/v1/slide-data")
            .then((response) => {
                const dataArray = Object.values(response.data).map((slide, ind) => {
                    return {
                        id: slide.id,
                        title: slide.title,
                        content: slide.content,
                        image: slide.image
                    };
                });

                setgetallSlideData(dataArray)
            })
            .catch((error) => console.log("error", error))
    }

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append('id', getallSlideData?.length + 1);
            formData.append('title', title);
            formData.append('content', content);
            formData.append('image', image);

            await axios.post('http://localhost/wp-headless/server/wp-json/custom/v1/slide-data', formData, {
                headers: {
                    'Authorization': 'Bearer ' + userDetails.token,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    getAllData()
                    settitle("")
                    setcontent("")
                })
                .catch((error) => {
                    console.log(error);
                })
        } catch (error) {
            console.error('Error adding slide data:', error);
        }
    };


    useEffect(() => {
        getAllData()
    }, [])

    return (
        <div>
            <div style={{ height: 250, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <input style={{ border: '1px solid grey', borderRadius: '20px', padding: '10px 20px' }} type="text" value={getallSlideData?.length + 1} disabled placeholder="ID" />
                <input style={{ border: '1px solid grey', borderRadius: '20px', padding: '10px 20px' }} type="text" value={title} onChange={(e) => settitle(e.target.value)} placeholder="title" />
                <input style={{ border: '1px solid grey', borderRadius: '20px', padding: '10px 20px' }} type="text" value={content} onChange={(e) => setcontent(e.target.value)} placeholder="content" />
                <input style={{ border: '1px solid grey', borderRadius: '20px', padding: '10px 20px' }} type="file" onChange={handleFileChange} />
                <button style={{ padding: '10px 20px', backgroundColor: 'rebeccapurple', color: '#fff', borderRadius: '20px' }} onClick={handleSubmit}>Add Slide Data</button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', border: '3px solid orange', borderRadius: '20px', padding: '10px', margin: '10px' }}>
                {getallSlideData?.map((data) =>
                    <div style={{ display: 'flex', flexDirection: 'row', border: '3px solid orange', alignItems: 'center', borderRadius: '20px', padding: '10px', margin: '10px' }}>
                        <p style={{ fontWeight: 'bold' }}>{data.title}</p>
                        <p>{data.content}</p>
                        <img src={data.image.url} style={{ width: '100px' }} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default SlideDataForm;
