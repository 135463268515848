import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Component/pages/Home";
import YourComponent from "./Component/pages/YourComponent";

const App = () => {
  return (
    <Router>
      {/* <Navbar /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/YourComponent" element={<YourComponent />} />
      </Routes>
    </Router>
  );
};

export default App;