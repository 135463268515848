import React from 'react';
import './CardGrid.css'; // Import your CSS file for styling

const CardGrid = () => {
    // Sample data for cards
    const cards = [
        { id: 1, title: 'Card 1', content: 'Content for Card 1' },
        { id: 2, title: 'Card 2', content: 'Content for Card 2' },
        { id: 3, title: 'Card 3', content: 'Content for Card 3' },
        { id: 4, title: 'Card 4', content: 'Content for Card 4' },
        { id: 5, title: 'Card 5', content: 'Content for Card 5' },
        { id: 6, title: 'Card 6', content: 'Content for Card 6' },
    ];

    return (
        <div className="card-grid">
            {cards.map(card => (
                <div key={card.id} className="card">
                    <h2>{card.title}</h2>
                    <p>{card.content}</p>
                </div>
            ))}
        </div>
    );
};

export default CardGrid;
