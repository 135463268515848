import { useEffect, useState } from 'react';
import axios from 'axios';
import Posts from '../Posts/Posts';
import Slideshow from '../contentSlideView';
import './Home.css'
import Navbar from '../Navbar/Navbar';
import CardGrid from '../CardGrid'

function Home() {
    const [postsData, setPostsData] = useState([]);
    const [getData, setGetData] = useState([]);
    const [loadig, setloadig] = useState(false);
    const [imagePath, setImagePath] = useState('');
    const [TrainingImagePath, setTrainingImagePath] = useState('');
    const [getallSlideData, setgetallSlideData] = useState([]);


    const userDetails = JSON.parse(localStorage.getItem('UID'))

    const getAllSlideContent = () => {
        axios.get("http://localhost/wp-headless/server/wp-json/custom/v1/slide-data")
            .then((response) => {
                const dataArray = Object.values(response.data).map((slide, ind) => {
                    return {
                        id: ind + 1,
                        title: slide.title,
                        content: slide.content,
                        image: slide.image
                    };
                });

                setgetallSlideData(dataArray)
            })
            .catch((error) => console.log("error", error))
    }

    const fetchPosts = async () => {
        try {
            setloadig(true)
            const response = await axios.get('http://localhost/wp-headless/server/wp-json/custom/v1/posts');
            setPostsData(response?.data?.filter((data) => data?.image !== false));
            if (response.status === 200) {
                setloadig(false)
            }
        } catch (error) {
            setloadig(false)
            console.error('Error fetching posts:', error);
        }
    };

    const handleGetImages = async () => {
        try {
            const response = await axios.get('http://localhost/wp-headless/server/wp-json/custom/v1/get-custom-images');
            setImagePath(response.data.image_url);
        } catch (error) {
            console.error('Error retrieving images:', error);
        }

        try {
            const response = await axios.get('http://localhost/wp-headless/server/wp-json/custom/v1/get-training-images');
            setTrainingImagePath(response.data.image_url);
        } catch (error) {
            console.error('Error retrieving images:', error);
        }
    };

    const handleGettrainingImages = async () => {
    };

    const fetchData = async () => {
        try {
            const response = await axios.get('http://localhost/wp-headless/server/wp-json/custom/v1/data');
            setGetData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchToken = async () => {
        axios.post('http://localhost/wp-headless/server/wp-json/jwt-auth/v1/token', {
            username: 'admin',
            password: 'admin@123'
        })
            .then(function (response) {
                localStorage.setItem('UID', JSON.stringify(response?.data));
            })

            .catch(function (error) {
                console.error('Error', error.data ? error.data[0] : '');
            });
    };


    useEffect(() => {
        fetchPosts();
        fetchData();
        fetchToken();
        handleGetImages();
        handleGettrainingImages();
        getAllSlideContent();
    }, []);

    function Accordion({ items }) {
        const [activeIndex, setActiveIndex] = useState(null);

        const onTitleClick = (index) => {
            setActiveIndex(index === activeIndex ? null : index);
        };

        const renderedItems = items.map((item, index) => {
            const isActive = index === activeIndex;
            return (
                <div style={{ padding: '10px' }} key={item.title}>
                    <div
                        className={`title ${isActive ? 'active' : ''}`}
                        onClick={() => onTitleClick(index)}
                    >
                        <i className="dropdown icon"></i>
                        {item.title}
                    </div>
                    <div className={`content ${isActive ? 'active' : ''}`}>
                        <p>{item.content}</p>
                    </div>
                </div>
            );
        });

        return <div className="ui styled accordion">{renderedItems}</div>;
    }

    if (loadig) {
        return (
            <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <img src='https://ideassionitt.com/wp-content/uploads/2020/09/ideassion-institute-of-talent-transformation-final-1.svg' height={{ height: '100%', width: '100%' }} />
            </div>
        )
    }

    return (
        <div style={{ width: '100%', overflow: 'auto' }}>
            <div style={{ height: '100px', width: '100%', backgroundColor: '#fff', }}>
                <Navbar />
            </div>
            <div style={{ width: '100%', backgroundColor: '#f6f7f9', display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '50%' }}>
                    <div>
                        <h1 style={{ color: 'rgb(60, 69, 146)', fontWeight: 'bolder', fontSize: '40px', padding: '70px' }}>
                            Your Transformation Journey Begins With Us
                        </h1>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <button style={{ border: '2px solid black', padding: '7px 15px 7px 15px', fontWeight: 'bold', borderRadius: '50px' }}>
                                For Individuals
                            </button>
                        </div>
                        <img src='https://ideassionitt.com/wp-content/uploads/2022/10/ban_new1.png' style={{
                            height: "auto",
                            maxWidth: "100%",
                            border: "none",
                            borderRadius: 0,
                            boxShadow: "none"
                        }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <button style={{ border: '1px solid black', backgroundColor: 'black', color: '#fff', fontWeight: 'bold', padding: '7px 15px 7px 15px', borderRadius: '50px' }}>
                                For Individuals
                            </button>
                        </div>
                        <img src='https://ideassionitt.com/wp-content/uploads/2022/10/ban_new2.png' style={{
                            height: "auto",
                            maxWidth: "100%",
                            border: "none",
                            borderRadius: 0,
                            boxShadow: "none"
                        }} />
                    </div>
                </div>
                <div style={{ width: '50%', height: '100%' }}>
                    <img src={imagePath} alt="Uploaded Image" />
                </div>
            </div>
            <div style={{ backgroundColor: "#F5F5F5", display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <div style={{ width: "60%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                    <h1 style={{ color: 'black', fontWeight: 'bold', fontSize: '40px', padding: '20px' }}>Why Choose Us</h1>
                    <Accordion items={getData} />
                </div>
                <div style={{ width: "40%", padding: '10px' }}>
                    <img src={TrainingImagePath} alt="Uploaded Image" />
                </div>
            </div>
            <div>
                <Slideshow postsData={getallSlideData} />
            </div>
            <div>
                <div style={{ width: '100%', display: 'flex', padding: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: '17px', fontWeight: 'bold', color: 'grey' }}>OUR OFFERINGS</p>
                    <p style={{ fontSize: '40px', fontWeight: 'bold' }}>Turning Expectations into Reality</p>
                </div>
                <div>
                    <Posts />
                </div>
                <div>
                    <CardGrid />
                </div>
            </div>
        </div>
    );
}

export default Home;
