import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UploadCustomImage from '../UploadCustomImage';
import SlideDataForm from '../SlideDataForm';
import FileInput from '../InputFileSelect';

const YourComponent = () => {
    const [postData, setPostData] = useState({ id: '', title: '', content: '' });
    const [getData, setGetData] = useState([]);


    const fetchData = async () => {
        try {
            const response = await axios.get('http://localhost/wp-headless/server/wp-json/custom/v1/data');
            setGetData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('http://localhost/wp-headless/server/wp-json/custom/v1/data', { title: postData?.title, content: postData?.content });
            fetchData()
            setPostData({ title: '', content: '' })
        } catch (error) {
            console.error('Error posting data:', error);
        }
    };

    useEffect(() => {
        fetchData()
    }, [])


    return (
        <div>
            <form onSubmit={handleSubmit}>
                <label style={{ height: 200, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                    <div style={{ textAlign: 'center' }}>Add Data</div>
                    <div style={{ textAlign: 'center' }}>
                        id : <input disabled style={{ border: '2px solid orange', padding: '10px', borderRadius: '20px' }} type="text" value={getData?.length + 1} />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        title : <input style={{ border: '2px solid orange', padding: '10px', borderRadius: '20px' }} type="text" value={postData.name} onChange={(e) => setPostData({ ...postData, title: e.target.value })} />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        content : <input style={{ border: '2px solid orange', padding: '10px', borderRadius: '20px' }} type="text" value={postData.name} onChange={(e) => setPostData({ ...postData, content: e.target.value })} />
                    </div>
                    <button style={{ padding: "5px 10px", backgroundColor: "rebeccapurple", color: '#fff', borderRadius: '20px', textAlign: 'center' }} type="submit">Submit</button>
                </label>
            </form>
            {getData.length > 0 ? getData?.map((data) => {
                return (
                    <div style={{ padding: 10 }}>
                        <div style={{ padding: "5px 10px", display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', backgroundColor: "rebeccapurple", color: '#fff', borderRadius: '20px', textAlign: 'center' }}>
                            {<p>{data?.id}</p>}
                            {<p>{data?.title}</p>}
                            {<p>{data?.content}</p>}
                        </div>
                    </div>
                )
            }) :
                <div>
                    No Data Found
                </div>}
            <UploadCustomImage />
            <SlideDataForm />
            <FileInput />
        </div>
    );
};

export default YourComponent;